import gql from "graphql-tag"

const CREATE_OR_UPDATE_CAMPAIGN = gql`
  mutation createOrUpdateCampaign(
    $id: ID!
    $name: String!
    $campaignGroupId: String!
    $priceRule: InputPriceRule!
    $productVariantRules: InputProductVariantRules!
    $roundingRules: InputRoundingRules
    $storeMarkets: [InputCampaignStoreMarket!]!
    $startDate: AWSDateTime!
    $endDate: AWSDateTime!
  ) {
    createOrUpdateCampaign(
      id: $id
      campaign: {
        name: $name
        campaignGroupId: $campaignGroupId
        priceRule: $priceRule
        productVariantRules: $productVariantRules
        roundingRules: $roundingRules
        storeMarkets: $storeMarkets
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      id
      campaignGroupId
      name
    }
  }
`

export default CREATE_OR_UPDATE_CAMPAIGN
