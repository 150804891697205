import React, { useState } from "react"
import PageHeader from "components/Ui/Page/PageHeader"
import { useMutation, useQuery } from "@apollo/client"
import PrimaryButton from "components/Ui/Buttons/PrimaryButton"

import { SubmitHandler, useForm } from "react-hook-form"
import alertActions from "lib/store/services/Alert/AlertSlice"
import { useAppDispatch } from "lib/store"
import { v4 as uuidv4 } from "uuid"

import {
  CampaignGroup,
  InputCampaign,
  InputCampaignStoreMarket,
  InputMarketRoundingRule,
  InputPriceRule,
  InputPriceRuleType,
  InputRoundingRules,
  StoreGroup
} from "lib/types/generated/graphql-types"
import CREATE_OR_UPDATE_CAMPAIGN from "graphql/mutations/campaign/CreateOrUpdateCampaign"
import General from "../../components/Campaign/General/General"
import { ButtonWrapper, IconLeft, IconRight } from "./CreateCampaign.styled"
import { CampaignStoreMarket } from "../../components/Campaign/StoreMarket/CampaignStoreMarket"
import SecondaryButton from "../../components/Ui/Buttons/SecondaryButton"
import ProductVariantRules from "../../components/Campaign/ProductVariantRules/ProductVariantRules"
import { RoundingRules } from "../../components/Campaign/RoundingRules/RoundingRules"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { CampaignTagRule } from "lib/types/campaign"
import PriceRule from "../../components/Campaign/PriceRule/PriceRule"
import ALL_STORE_GROUPS from "../../graphql/queries/store/AllStoreGroups"
import SEARCH_CAMPAIGN_GROUPS from "../../graphql/queries/campaign/SearchCampaignGroups"
import { useNavigate, useParams } from "react-router-dom"
import { isSuperUser } from "helpers/user"
import GET_CAMPAIGN from "../../graphql/queries/campaign/getCampaign"
import styled from "styled-components/macro"

export const Blur = styled.div`
  filter: blur(0.3rem);
  -webkit-filter: blur(0.3rem);
`

enum Step {
  VARIANT_RULES,
  STORE_MARKETS,
  PRICE_RULES,
  ROUNDING_RULES,
  GENERAL
}

export const DuplicateCampaign = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [storeGroups, setStoreGroups] = useState<StoreGroup[]>()
  const [tagRules, setTagRules] = useState<CampaignTagRule[]>([{ tagConditions: [] }])
  const [addedStoreMarkets, setAddedStoreMarkets] = useState<InputCampaignStoreMarket[]>([])
  const [marketRules, setMarketRules] = useState<InputMarketRoundingRule[]>([])
  const [priceRule, setPriceRule] = useState<InputPriceRule>({
    type: InputPriceRuleType.RelativeDiscount
  })
  const [roundingRules, setRoundingRules] = useState<InputRoundingRules>()
  const [step, setStep] = useState<Step>(Step.VARIANT_RULES)
  const [campaignGroups, setCampaignGroups] = useState<CampaignGroup[]>([])

  const { data: campaignGroupsData } = useQuery(SEARCH_CAMPAIGN_GROUPS, {
    variables: {
      from: 0,
      size: 40,
      sort: [{ field: "updated", order: "DESC" }]
    },
    onCompleted: () => {
      setCampaignGroups(campaignGroupsData?.searchCampaignGroups?.hits)
    }
  })

  const transformJSONLogicToTagRules = (logic: string) => {
    const tagLogic = JSON.parse(logic)
    // eslint-disable-next-line
    const transformed = tagLogic.or.map((rule: any) => ({
      // eslint-disable-next-line
      tagConditions: rule.and.map((condition: any) =>
        Object.keys(condition)[0] === "contains"
          ? {
              key: condition.contains.key,
              values: condition.contains.values,
              include: true
            }
          : {
              key: condition["!"].contains.key,
              values: condition["!"].contains.values,
              include: false
            }
      )
    }))
    setTagRules(transformed)
  }

  const { data, loading: getLoading } = useQuery(GET_CAMPAIGN, {
    variables: { id: id },
    onCompleted: (data) => {
      const { storeMarkets, priceRule, roundingRules, productVariantRules } = data.getCampaign
      transformJSONLogicToTagRules(productVariantRules.tagLogic)
      setAddedStoreMarkets(storeMarkets)
      setPriceRule(priceRule)
      setRoundingRules(roundingRules)
    }
  })

  const campaign = data?.getCampaign

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues
  } = useForm<InputCampaign>()

  useQuery(ALL_STORE_GROUPS, {
    onCompleted: (data) => {
      setStoreGroups(data.getStoreGroups)
    }
  })

  const [addCampaign, { loading: createLoading }] = useMutation(CREATE_OR_UPDATE_CAMPAIGN, {
    onCompleted: (data) => {
      dispatch(
        alertActions.actions.createAlert({
          message: "Campaign successfully created",
          type: "success"
        })
      )
      navigate(`/campaigns/campaign/${data.createOrUpdateCampaign.id}`)
    },
    onError: (error) => {
      dispatch(
        alertActions.actions.createAlert({
          message: error.message,
          type: "error"
        })
      )
    }
  })

  const getTagLogicJSON = () => {
    const transformed = {
      or: tagRules.map((tagRule) => ({
        and: tagRule.tagConditions.map((tagCondition) => ({
          ...(tagCondition.include
            ? {
                contains: {
                  key: tagCondition.key,
                  values: tagCondition.values
                }
              }
            : {
                ["!"]: {
                  contains: {
                    key: tagCondition.key,
                    values: tagCondition.values
                  }
                }
              })
        }))
      }))
    }
    return JSON.stringify(transformed)
  }

  const onSubmit: SubmitHandler<InputCampaign> = (data) => {
    addCampaign({
      variables: {
        campaignGroupId: data.campaignGroupId,
        name: data.name,
        id: uuidv4(),
        priceRule: priceRule,
        storeMarkets: addedStoreMarkets,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
        ...(roundingRules?.defaultRule.targets
          ? {
              roundingRules: {
                defaultRule: roundingRules?.defaultRule,
                marketRules: roundingRules?.marketRules ?? []
              }
            }
          : { roundingRules: null }),
        productVariantRules: {
          tagLogic: getTagLogicJSON()
        }
      }
    })
  }

  dayjs.extend(utc)
  dayjs.extend(timezone)
  const getOperatorCheck = () => {
    if (priceRule.type === InputPriceRuleType.RelativeDiscount) {
      return (
        !priceRule.percentage ||
        priceRule.percentageDecimals === null ||
        priceRule.percentageDecimals === undefined ||
        isNaN(priceRule.percentageDecimals)
      )
    } else {
      return !priceRule.monies || priceRule.monies.length === 0
    }
  }

  if (getLoading)
    return (
      <>
        <PageHeader
          title={`Duplicate campaign`}
          group="Campaigns"
          id=" "
          hasTabs
          goBackLinkUrl={`/campaigns`}
          goBackLinkText="Back to campaign"
        ></PageHeader>
        <Blur>
          <ProductVariantRules tagRules={tagRules} setTagRules={setTagRules} />
          <ButtonWrapper>
            <PrimaryButton
              handleClick={() => {
                setStep(Step.STORE_MARKETS)
              }}
            >
              Store markets
              <IconRight />
            </PrimaryButton>
          </ButtonWrapper>
        </Blur>
      </>
    )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        title={`Duplicate campaign ${campaign?.name}`}
        id={campaign.id}
        group="Campaigns"
        hasTabs
        goBackLinkUrl={`/campaigns/campaign/${campaign.id}`}
        goBackLinkText="Back to campaign"
      ></PageHeader>
      {step === Step.VARIANT_RULES && (
        <>
          <ProductVariantRules tagRules={tagRules} setTagRules={setTagRules} />
          <ButtonWrapper>
            <PrimaryButton
              overrideDisabled={!isSuperUser() && tagRules[0]?.tagConditions.length !== 0}
              disabled={tagRules[0]?.tagConditions?.length === 0}
              handleClick={() => {
                setStep(Step.STORE_MARKETS)
              }}
            >
              Store markets
              <IconRight />
            </PrimaryButton>
          </ButtonWrapper>
        </>
      )}
      {step === Step.STORE_MARKETS && (
        <>
          <CampaignStoreMarket
            addedStoreMarkets={addedStoreMarkets}
            setAddedStoreMarkets={setAddedStoreMarkets}
            storeGroups={storeGroups}
          />
          <ButtonWrapper>
            <SecondaryButton
              overrideDisabled={!isSuperUser()}
              handleClick={() => {
                setStep(Step.VARIANT_RULES)
              }}
            >
              <IconLeft />
              Back
            </SecondaryButton>
            <PrimaryButton
              overrideDisabled={!isSuperUser() && addedStoreMarkets.length !== 0}
              disabled={addedStoreMarkets.length === 0}
              handleClick={() => {
                setStep(Step.PRICE_RULES)
              }}
            >
              Price rules
              <IconRight />
            </PrimaryButton>
          </ButtonWrapper>
        </>
      )}
      {step === Step.PRICE_RULES && (
        <>
          <PriceRule
            priceRule={priceRule}
            setPriceRule={setPriceRule}
            addedStoreMarkets={addedStoreMarkets}
            storeGroups={storeGroups}
          />

          <ButtonWrapper>
            <SecondaryButton
              overrideDisabled={!isSuperUser()}
              handleClick={() => {
                setStep(Step.STORE_MARKETS)
              }}
            >
              <IconLeft />
              Back
            </SecondaryButton>
            <PrimaryButton
              overrideDisabled={!isSuperUser() && !getOperatorCheck()}
              disabled={getOperatorCheck()}
              handleClick={() => {
                if (priceRule.type !== InputPriceRuleType.RelativeDiscount) {
                  setStep(Step.GENERAL)
                } else {
                  setStep(Step.ROUNDING_RULES)
                }
              }}
            >
              {priceRule.type !== InputPriceRuleType.RelativeDiscount
                ? "General"
                : "Rounding rules"}
              <IconRight />
            </PrimaryButton>
          </ButtonWrapper>
        </>
      )}
      {step === Step.ROUNDING_RULES && (
        <>
          <RoundingRules
            marketRules={marketRules}
            setMarketRules={setMarketRules}
            addedStoreMarkets={addedStoreMarkets}
            roundingRules={roundingRules}
            setRoundingRules={setRoundingRules}
          />
          <ButtonWrapper>
            <SecondaryButton
              overrideDisabled={!isSuperUser()}
              handleClick={() => {
                setStep(Step.PRICE_RULES)
              }}
            >
              <IconLeft />
              Back
            </SecondaryButton>
            <PrimaryButton
              overrideDisabled={!isSuperUser()}
              handleClick={() => {
                setStep(Step.GENERAL)
              }}
            >
              General
              <IconRight />
            </PrimaryButton>
          </ButtonWrapper>
        </>
      )}
      {step === Step.GENERAL && (
        <>
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            campaignGroups={campaignGroups}
            setCampaignGroups={setCampaignGroups}
            getValues={getValues}
            startDateValidation={true}
            defaultValues={{
              campaignGroupId: campaign.campaignGroupId
            }}
          />
          <ButtonWrapper>
            <SecondaryButton
              overrideDisabled={!isSuperUser()}
              handleClick={() => {
                if (priceRule.type !== InputPriceRuleType.RelativeDiscount) {
                  setStep(Step.PRICE_RULES)
                } else {
                  setStep(Step.ROUNDING_RULES)
                }
              }}
            >
              <IconLeft />
              Back
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              handleClick={handleSubmit(onSubmit)}
              loading={createLoading}
            >
              Save
            </PrimaryButton>
          </ButtonWrapper>
        </>
      )}
    </form>
  )
}
