import React, { useState } from "react"

import DineroFactory from "dinero.js"

import {
  ActionWrapper,
  Amount,
  CartItemContainer,
  CartItemWrapper,
  InfoWrapper,
  InputContainer,
  ItemDetails,
  ItemImage,
  ItemName,
  ItemPrice,
  QtySelector
} from "./CartItem.styled"
import Money from "../../../../Money"

import placeholder from "../../../../../images/placeholder.svg"
import {
  CompensationOrderLine,
  OrderCompensation,
  OrderLine
} from "lib/types/generated/graphql-types"
import SecondaryButton from "../../../../Ui/Buttons/SecondaryButton"
import { ButtonWrapper } from "../Shared.styled"
import { useMutation } from "@apollo/client"
import UPDATE_COMPENSATION from "../../../../../graphql/mutations/order/compensation/UpdateCompensation"
import {
  compensationOrderLineOutputToInput,
  compensationOrderLinesOutputToInput,
  getCompensationInput
} from "../Helpers/helpers"
import Input from "../../../../Ui/Form/Input"
import PrimaryButton from "../../../../Ui/Buttons/PrimaryButton"
import { ReactComponent as ChevronDown } from "images/icons/chevron-down.svg"
import { ReactComponent as ChevronUp } from "images/icons/chevron-up.svg"
import { handleOrderManagementError } from "../../Helpers/errors"

type AmountInput = {
  totalAmount: number | null
  totalDiscountAmount: number | null
}

type Props = {
  item: CompensationOrderLine
  currencyCode?: string
  orderLine: OrderLine | undefined
  compensation: OrderCompensation
  refetch: () => void
}

const CartItem = ({ item, currencyCode, orderLine, compensation, refetch, ...props }: Props) => {
  const [quantity, setQuantity] = useState(item.quantity)
  const [amountInput, setAmountInput] = useState<AmountInput>({
    totalAmount: item.totalAmount,
    totalDiscountAmount: item.totalDiscountAmount
  })
  const [amountExpanded, setAmountExpanded] = useState(false)

  const orderLines = compensation.orderLines ?? []

  const [updateCompensation, { loading }] = useMutation(UPDATE_COMPENSATION, {
    onCompleted: () => {
      refetch()
      setAmountExpanded(false)
    },
    onError: (error) => {
      handleOrderManagementError(error)
    }
  })

  const onUpdate = (qty: number) => {
    if (qty === 0) {
      return updateCompensation({
        variables: {
          compensationId: compensation.id,
          input: {
            ...getCompensationInput(compensation),
            orderLines: compensationOrderLinesOutputToInput(
              orderLines.filter((ol) => ol.orderLineId !== item.orderLineId)
            )
          }
        }
      })
    }
    return updateCompensation({
      variables: {
        compensationId: compensation.id,
        input: {
          ...getCompensationInput(compensation),
          orderLines: [
            ...orderLines.map((ol) => ({
              ...compensationOrderLineOutputToInput(ol),
              quantity: ol.orderLineId === item.orderLineId ? qty : ol.quantity
            }))
          ]
        }
      }
    })
  }

  const updateAmount = () => {
    if (amountInput.totalAmount && amountInput.totalDiscountAmount) {
      updateCompensation({
        variables: {
          compensationId: compensation.id,
          input: {
            ...getCompensationInput(compensation),
            orderLines: [
              ...orderLines.map((ol) => ({
                ...compensationOrderLineOutputToInput(ol),
                ...(ol?.orderLineId === item.orderLineId && {
                  totalAmount: amountInput.totalAmount
                }),
                ...(ol?.orderLineId === item.orderLineId && {
                  totalDiscountAmount: amountInput.totalDiscountAmount
                })
              }))
            ]
          }
        }
      })
    }
  }

  const updateQuantity = (mod: number) => {
    const qty = Math.max(quantity + mod, 0)
    setQuantity(qty)
    onUpdate(qty)
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = placeholder
  }

  return (
    <>
      <CartItemWrapper>
        <CartItemContainer {...props}>
          <InfoWrapper>
            <ItemImage>
              <img src={orderLine?.imageUrl ?? ""} onError={handleImageError} />
            </ItemImage>
            <ItemDetails>
              <ItemName>{orderLine?.name}</ItemName>
              <ItemPrice>
                <div>Amount:</div>
                <Money
                  amount={Number(item.totalAmount)}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </ItemPrice>
              {item?.totalDiscountAmount && (
                <ItemPrice>
                  <div>Discount:</div>
                  <Money
                    amount={Number(item?.totalDiscountAmount)}
                    currencyUnit={currencyCode as DineroFactory.Currency}
                  />
                </ItemPrice>
              )}
            </ItemDetails>
          </InfoWrapper>

          <ActionWrapper $loading={loading}>
            <span>
              <b>Action:</b>{" "}
              {orderLine?.quantity === item.quantity
                ? "Will be included"
                : "Will be included in part"}
            </span>
            <span>
              <b>Items to include:</b>
              <QtySelector>
                <span onClick={() => updateQuantity(-1)}>-</span>
                <span>{item.quantity}</span>
                {
                  <span
                    onClick={() => {
                      if (orderLine?.quantity !== quantity) updateQuantity(+1)
                    }}
                  >
                    +
                  </span>
                }
              </QtySelector>
            </span>
            <hr />
            <ButtonWrapper>
              <SecondaryButton handleClick={() => onUpdate(0)}>Undo</SecondaryButton>
            </ButtonWrapper>
          </ActionWrapper>
        </CartItemContainer>
        <Amount onClick={() => setAmountExpanded(!amountExpanded)}>
          Change amount {amountExpanded ? <ChevronUp /> : <ChevronDown />}
        </Amount>
        {amountExpanded && (
          <InputContainer>
            <Input
              name={"totalAmount"}
              label={"New Amount"}
              type={"number"}
              defaultValue={item.totalAmount}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setAmountInput({
                  ...amountInput,
                  totalAmount: e.currentTarget.value !== "" ? parseInt(e.currentTarget.value) : 0
                })
              }
            />
            <Input
              name={"totalDiscountAmount"}
              label={"New Discount"}
              type={"number"}
              defaultValue={item.totalDiscountAmount}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setAmountInput({
                  ...amountInput,
                  totalDiscountAmount:
                    e.currentTarget.value !== "" ? parseInt(e.currentTarget.value) : 0
                })
              }
            />
            <PrimaryButton
              handleClick={() => updateAmount()}
              disabled={!amountInput.totalAmount || !amountInput.totalDiscountAmount}
            >
              Update
            </PrimaryButton>
            {amountInput?.totalAmount ? (
              <div>
                <b>Formatted:</b>{" "}
                <Money
                  amount={amountInput.totalAmount ?? 0}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </div>
            ) : (
              <div></div>
            )}
            {amountInput?.totalDiscountAmount ? (
              <div>
                <b>Formatted:</b>{" "}
                <Money
                  amount={amountInput.totalDiscountAmount ?? 0}
                  currencyUnit={currencyCode as DineroFactory.Currency}
                />
              </div>
            ) : (
              <div />
            )}
          </InputContainer>
        )}
      </CartItemWrapper>
    </>
  )
}

export default CartItem
