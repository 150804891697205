import React, { Component, ErrorInfo, ReactNode } from "react"
import { useConfig } from "configuration/useConfig"
import { AwsRumClient } from "helpers/awsRumClient"
import { AwsRum } from "aws-rum-web"
import ErrorView from "./views/ErrorView"

interface ErrorBoundaryProps {
  children: ReactNode
  rumIdentityPool: string
  rumAppMonitorId: string
  environment: string
  awsRegion: string
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {

  private rumIdentityPool: string
  private rumAppMonitorId: string
  private environment: string
  private awsRegion: string

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.rumIdentityPool = props.rumIdentityPool
    this.rumAppMonitorId = props.rumAppMonitorId
    this.environment = props.environment
    this.awsRegion = props.awsRegion
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (this.environment === 'development') {
      return
    }
    try {
      const awsRumClient: AwsRum = AwsRumClient(this.rumAppMonitorId, this.rumIdentityPool, this.awsRegion)
      awsRumClient.recordError(error)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error caught by RUMclient:", error)
    }
    // eslint-disable-next-line no-console
    console.error("Error caught by ErrorBoundary:", error, errorInfo)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (<ErrorView/>)
    }
    return this.props.children
  }
}

interface ErrorBoundaryWarpperProps {
  children: ReactNode
}

export const ErrorBoundaryWrapper = ({ children }: ErrorBoundaryWarpperProps) => {
  const { rumAppMonitorId, rumIdentityPool: rumIdentityPool, environment, awsRegion } = useConfig().config
  return <ErrorBoundary
    rumAppMonitorId={rumAppMonitorId}
    rumIdentityPool={rumIdentityPool}
    environment={environment}
    awsRegion={awsRegion}>
    {children}
  </ErrorBoundary>
}
