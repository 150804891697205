import React, { Dispatch, SetStateAction, useState } from "react"
import { TooltipIcon } from "components/Ui/TooltipIcon"
import { isSuperUser, isCampaignUser } from "helpers/user"

import { v4 as uuidv4 } from "uuid"
import {
  InputCampaignStoreMarket,
  InputMarketRoundingRule,
  InputRoundingRules
} from "lib/types/generated/graphql-types"
import { Common } from "lib/types"

import { ReactComponent as PlusIcon } from "images/icons/plus.svg"

import MultiSelect from "components/Ui/Form/MultiSelect"
import CheckboxSimple from "components/Ui/Form/CheckboxSimple"
import Popup from "components/Ui/Popup"
import { getCountryName } from "helpers/countries"

import {
  AddButton,
  CheckboxWrapper,
  Country,
  CountrySelect,
  MarketRuleRow,
  MarketRules,
  MarketRulesHeader,
  Value
} from "./MarketRoundingRules.styled"

type Props = {
  commonTargetsOptions: Common.Option[]
  marketRules: InputMarketRoundingRule[]
  setMarketRules: Dispatch<SetStateAction<InputMarketRoundingRule[]>>
  roundingRules: InputRoundingRules | undefined
  setRoundingRules: Dispatch<SetStateAction<InputRoundingRules | undefined>>
  addedStoreMarkets: InputCampaignStoreMarket[]
  isCampaignOngoing?: boolean
}

export const MarketRoundingRules = ({
  commonTargetsOptions,
  addedStoreMarkets,
  roundingRules,
  setRoundingRules,
  isCampaignOngoing
}: Props) => {
  const [index, setIndex] = useState<number>(0)
  const [marketToAdd, setMarketToAdd] = useState<InputMarketRoundingRule>()
  const [countryToDelete, setCountryToDelete] = useState<string>("")
  const [openPopup, setOpenPopup] = useState(false)

  const getCountryOptions = () => {
    const countryArray = [...new Set(addedStoreMarkets.flatMap((as) => as.value))]
    const addedCountries = roundingRules?.marketRules?.map((marketRule) => marketRule.key) ?? []

    return countryArray
      .filter((val) => !addedCountries.includes(val))
      .map((countryCode) => ({
        value: countryCode,
        label: getCountryName(countryCode) ?? ""
      }))
  }

  const addMarketRule = () => {
    let marketRules = roundingRules?.marketRules ?? []
    if (marketToAdd) marketRules = [...marketRules, marketToAdd]
    if (roundingRules?.defaultRule) {
      setRoundingRules({ defaultRule: roundingRules?.defaultRule, marketRules: marketRules })
      setMarketToAdd(undefined)
    }
  }

  const onDeleteMarketRule = (key: string) => {
    const marketRules = roundingRules?.marketRules ?? []
    if (roundingRules?.defaultRule) {
      setRoundingRules({
        defaultRule: roundingRules?.defaultRule,
        marketRules: marketRules.filter((marketRule) => marketRule.key !== key)
      })
    }
    setOpenPopup(false)
  }

  const updateMarketRoundUp = (value: boolean) => {
    setMarketToAdd({
      key: marketToAdd?.key ?? "",
      value: {
        roundUp: value,
        roundDown: marketToAdd?.value?.roundDown ?? false,
        targets: marketToAdd?.value?.targets ?? []
      }
    })
  }

  const updateMarketRoundDown = (value: boolean) => {
    setMarketToAdd({
      key: marketToAdd?.key ?? "",
      value: {
        roundUp: marketToAdd?.value?.roundUp ?? false,
        roundDown: value,
        targets: marketToAdd?.value?.targets ?? []
      }
    })
  }

  const updateMarketTargets = (options: Common.Option[]) => {
    setMarketToAdd({
      key: marketToAdd?.key ?? "",
      value: {
        roundUp: marketToAdd?.value?.roundUp ?? false,
        roundDown: marketToAdd?.value?.roundDown ?? false,
        targets: options.map((option) => option.value) ?? []
      }
    })
  }

  const updateMarketKey = (value: string) => {
    setMarketToAdd({
      key: value,
      value: {
        roundUp: marketToAdd?.value?.roundUp ?? false,
        roundDown: marketToAdd?.value?.roundDown ?? false,
        targets: marketToAdd?.value?.targets ?? []
      }
    })
  }

  const getRoundingText = (roundUp: boolean, roundDown: boolean) => {
    if (roundUp && !roundDown) return "Round up"
    if (!roundUp && roundDown) return "Round down"
    if (!roundUp && !roundDown) return "No rounding"
    if (roundUp && roundDown) return "Round closest"
  }

  return (
    <div>
      <h3>Market rules - Rounding rules for specific countries (Optional)</h3>
      {roundingRules?.defaultRule && roundingRules?.defaultRule?.targets?.length > 0 ? (
        <>
          <CountrySelect
            options={getCountryOptions() ?? []}
            setValue={updateMarketKey}
            value={
              marketToAdd?.key
                ? { value: marketToAdd?.key, label: getCountryName(marketToAdd?.key ?? "") ?? "" }
                : null
            }
            placeholder="Select market"
            label="Market:"
          />
          {marketToAdd?.key && (
            <>
              <MultiSelect
                label={
                  <>
                    {"Targets *"}
                    <TooltipIcon content="The targets specify the numbers that the price (in minor units) will end with. The target closest to the sale price will be chosen." />
                  </>
                }
                value={
                  marketToAdd?.value?.targets.map((target) => ({ value: target, label: target })) ??
                  []
                }
                disabled={isCampaignOngoing}
                options={commonTargetsOptions}
                setValue={updateMarketTargets}
              />
              <CheckboxWrapper>
                <CheckboxSimple
                  name={`marketRoundDown`}
                  label="Round down"
                  value={marketToAdd?.value?.roundDown ?? false}
                  setValue={updateMarketRoundDown}
                  disabled={isCampaignOngoing}
                />
                <CheckboxSimple
                  name={`marketRoundUp`}
                  value={marketToAdd?.value?.roundUp ?? false}
                  label="Round up"
                  setValue={updateMarketRoundUp}
                  disabled={isCampaignOngoing}
                />
              </CheckboxWrapper>

              <AddButton
                type="button"
                overrideDisabled={marketToAdd.value?.targets.length !== 0 && !isSuperUser()}
                disabled={marketToAdd.value?.targets.length === 0 || isCampaignOngoing}
                handleClick={() => {
                  setIndex(index + 1)
                  addMarketRule()
                }}
              >
                <PlusIcon />
                Add
              </AddButton>
            </>
          )}

          <MarketRules>
            {roundingRules?.marketRules && roundingRules?.marketRules?.length > 0 ? (
              <>
                <hr />
                <MarketRulesHeader>
                  <div>Country</div>
                  <div>Targets</div>
                  <div>Rounding</div>
                </MarketRulesHeader>
                <div>
                  {roundingRules?.marketRules?.map((marketRule) => {
                    return (
                      <React.Fragment key={uuidv4()}>
                        <MarketRuleRow
                          deleteEnabled={(isSuperUser() || isCampaignUser()) && !isCampaignOngoing}
                          smallButtons
                          disableHover
                          handleDelete={() => {
                            setCountryToDelete(marketRule.key)
                            setOpenPopup(true)
                          }}
                        >
                          <Country>
                            <span> {getCountryName(marketRule.key)}</span>
                          </Country>
                          <Value>
                            <span>
                              {marketRule?.value?.targets.map((target) => target).join(", ")}
                            </span>
                          </Value>
                          <Value>
                            <span>
                              {getRoundingText(
                                marketRule?.value?.roundUp ?? false,
                                marketRule.value?.roundDown ?? false
                              )}
                            </span>
                          </Value>
                        </MarketRuleRow>
                        {openPopup && countryToDelete === marketRule.key && (
                          <Popup
                            title="Delete market rule"
                            subtitle={`Are you sure you want to delete the following market rule?`}
                            item={{ itemId: "", itemName: getCountryName(marketRule.key) ?? "" }}
                            handleCloseClick={() => setOpenPopup(false)}
                            handleOkClick={() => onDeleteMarketRule(marketRule.key)}
                            type="delete"
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              </>
            ) : (
              <p>No market rules added</p>
            )}
          </MarketRules>
        </>
      ) : (
        <p> Set a default rule first</p>
      )}
    </div>
  )
}
