import React from "react"
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { createAuthLink } from "aws-appsync-auth-link"
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link"
import { merge } from "lodash"
import type { Common } from "@lib/types"
import { useConfig } from "../../configuration/useConfig"
import { fetchAuthSession } from "aws-amplify/auth"

type Props = {
  children: JSX.Element
}

const ApolloClientProvider = ({ children }: Props) => {
  const { awsRegion, graphqlApiUrl } = useConfig().config

  const url: string = graphqlApiUrl
  const region: string = awsRegion
  const auth: Common.AuthOptions = {
    type: "OPENID_CONNECT",
    jwtToken: async () =>
      (await fetchAuthSession().then((res) => res.tokens?.accessToken.toString())) ?? ""
  }

  const parseCustomerAttribute = (value: string | object): object => {
    switch (typeof value) {
      case "string":
        return parseCustomerAttribute(JSON.parse(value))
      case "object":
        return value
      default:
        return {}
    }
  }

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(   // eslint-disable-line
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            )
          )
        if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`) // eslint-disable-line
      }),
      createAuthLink({ url, region, auth }),
      createSubscriptionHandshakeLink({ url, region, auth })
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Order: {
          fields: {
            shippingAddress: {
              merge: true
            },
            customerAttribute: {
              merge(existing, incoming) {
                return merge({}, parseCustomerAttribute(existing), parseCustomerAttribute(incoming))
              }
            }
          }
        }
      },
      addTypename: false
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network"
      }
    }
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { ApolloClientProvider }
